import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import type { dateISO } from '@/shared/model/types/Kernel';
import { dayjs } from '@/shared/lib/dayjs';
import { generateUUID, logger } from '@/shared/model/utils';
import client from '@/shared/api/client';
import * as yup from 'yup';
import sentry from '@/shared/lib/sentry/sentry';
import { type ActionPromoId, type ActionPromoReport, ReportActionType } from '../model/types';
import type { CompanyId } from '@/shared/model/types/Company';

interface ActionReportsParams extends Partial<ServerPagination> {
    ids?: ActionPromoId[];
    typeAction: ReportActionType;
    supplierIds?: number[];
    startDate: null | dateISO;
    endDate: null | dateISO;
    limitDate: string;
}

const infoRepItemSchema = yup
    .object()
    .transform(value => {
        return {
            ...value,
            supplierCode: value.supplier_code
        };
    })
    .shape({
        nmActions: yup.number<ActionPromoId>().integer().required(),
        actionName: yup.string().required(),
        sDate: yup
            .string()
            .matches(/^\d{2}\.\d{2}\.\d{4}$/)
            .required(),
        eDate: yup
            .string()
            .matches(/^\d{2}\.\d{2}\.\d{4}$/)
            .required(),
        supplierCode: yup.number().integer().required()
    });

const infoRepSchema = yup
    .object()
    .transform(value => {
        if (value === null) {
            return {
                infoRep: [],
                recordCount: 0
            };
        } else {
            return {
                infoRep: value.infoRep,
                // number | null
                recordCount: value.record_count || 0
            };
        }
    })
    .shape({
        infoRep: yup
            .array()
            .of(infoRepItemSchema)
            .required()
            .transform(value => (Array.isArray(value) ? value : [])),
        recordCount: yup.number().integer().required()
    })
    .required();
const actionReportResponseSchema = yup
    .object()
    .transform(value => {
        return {
            ...value,
            creationDate: value.creation_date,
            correlationId: value.correlation_id,
            totalRecordCount: value.total_record_count
        };
    })
    .shape({
        status: yup.string().matches(/^(\d+)$/),
        message: yup.string().nullable(),
        creationDate: yup.string().matches(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/),
        correlationId: yup.string().nullable(),
        totalRecordCount: yup.number().integer().required(),
        data: yup
            .object()
            .shape({
                infoRep: yup.object().shape({
                    infoRep35: infoRepSchema,
                    infoRep60: infoRepSchema,
                    infoRep89: infoRepSchema
                })
            })
            .required()
    });

export const getActionReports = async (
    payload: ActionReportsParams
): Promise<{
    pagination: Pagination;
    reports: {
        infoRep35: {
            infoRep: ActionPromoReport[];
            recordCount: number;
        };
        infoRep60: {
            infoRep: ActionPromoReport[];
            recordCount: number;
        };
        infoRep89: {
            infoRep: ActionPromoReport[];
            recordCount: number;
        };
    };
}> => {
    let startFrom = 0;
    const currentPage = payload?.currentPage ?? 1;
    const itemsPerPage = payload?.itemsPerPage ?? 10;

    if (payload?.currentPage) {
        startFrom = (currentPage - 1) * itemsPerPage;
    }

    const serverPayload: Record<string, unknown> = {
        supplier_code: Array.isArray(payload.supplierIds) ? payload.supplierIds : [],
        nm_actions: Array.isArray(payload.ids) ? payload.ids : [],
        type_actions: payload.typeAction,
        start_from: startFrom,
        count: itemsPerPage,
        sDate:
            (payload.startDate &&
                dayjs(payload.startDate).isValid() &&
                dayjs(payload.startDate).format('DD.MM.YYYY')) ||
            undefined,
        eDate:
            (payload.endDate && dayjs(payload.endDate).isValid() && dayjs(payload.endDate).format('DD.MM.YYYY')) ||
            undefined,
        limit_date: payload.limitDate,
        correlation_id: generateUUID()
    };

    const response = await client.post('/api/proxy/report/rep-actions', serverPayload);

    await actionReportResponseSchema.validate(response.data).catch(error => {
        const message = 'Не валидный ответ /api/proxy/report/rep-actions';
        sentry.captureMessage(message);
        sentry.captureException(error);
        logger.warn(message);
        logger.error(error);
    });

    const data = actionReportResponseSchema.cast(response.data, {
        stripUnknown: true,
        assert: false
    });

    const pagination: Pagination = {
        currentPage,
        itemsPerPage,
        totalItems: Math.max(
            data.data.infoRep.infoRep35.recordCount,
            data.data.infoRep.infoRep60.recordCount,
            data.data.infoRep.infoRep89.recordCount
        )
    };

    return {
        reports: data.data.infoRep,
        pagination
    };
};

export const getStartDates = async (
    companyId: CompanyId
): Promise<{
    b2b: string | null;
    b2c: string | null;
}> => {
    const response = await client.get(`/api/v1/report-packages/action-promo-start-date?companyId=${companyId}`);
    return response.data;
};
