import client from '@/shared/api/client';
import type { Medicine, MedicineId } from '@/entities/Medicine/model/Medicine';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import { fileSaveAs } from '@/shared/model/utils';

export interface MedicationsParams {
    search?: number | string;
    wabcCodes?: number | number[];
    medicineCodes?: MedicineId | MedicineId[];
    pagination?: Partial<ServerPagination>;
}

/**
 * Справочник кодов / названий товаров из Здравсити
 */
export const getMedications = async (
    payload: MedicationsParams,
    abortController?: AbortController
): Promise<{ data: Medicine[]; pagination: Pagination }> => {
    const params: {
        page: number;
        limit: number;
        search?: number | string;
        wabcCodes?: string;
        medicineCodes?: string;
    } = {
        page: payload.pagination?.currentPage ?? 1,
        limit: payload.pagination?.itemsPerPage ?? 15
    };

    if (payload.search) {
        params.search = payload.search;
    }

    if (payload.wabcCodes) {
        params.wabcCodes = String(payload.wabcCodes);
    }

    if (payload.medicineCodes) {
        params.medicineCodes = String(payload.medicineCodes);
    }

    const { data } = await client.get<{ data: object[]; total: number }>('/api/handbooks/medications', {
        params,
        signal: abortController?.signal
    });

    return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: data.data.map((item: any) => ({
            currentWabcName: item.current_wabc_name,
            fullName: item.full_name,
            medicineCode: item.medicine_code
        })),
        pagination: {
            currentPage: payload.pagination?.currentPage ?? 1,
            itemsPerPage: payload.pagination?.itemsPerPage ?? 15,
            totalItems: data.total
        }
    };
};

export const medicineExcelExport = async (data: Pick<Medicine, 'medicineCode' | 'currentWabcName'>[]) => {
    const payload = data.map(item => {
        return {
            protekCode: item.medicineCode,
            name: item.currentWabcName
        };
    });

    const response = await client({
        method: 'post',
        url: '/api/v1/products/assortment/export',
        responseType: 'blob',
        data: { data: payload }
    });

    fileSaveAs(response.data, `Список товаров.xlsx`);
};
