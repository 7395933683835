<template>
    <div v-loading.fullscreen="isInitApp">
        <section v-if="isMobileDevice" class="layout">
            <MobileOverlay />
        </section>

        <section v-else-if="isErrorInitApp" class="layout">
            <ErrorOverlay />
        </section>

        <component :is="layout" v-else>
            <router-view />
        </component>

        <ImpersonateAlert />
        <NotificationWrapper />
        <ConfirmWrapper />
    </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue';

import { notifyCollapse } from '@/shared/model/utils';
import { ErrorOverlay, ImpersonateAlert, MobileOverlay } from '@/layout';
import { useRoute, useRouter } from 'vue-router';
import { ConfirmWrapper, NotificationWrapper } from '@/shared/ui';
import { useUserStore } from '@/shared/model/store/UserStore';
import { CompanyStatus } from '@/models';
import DefaultLayout from '@/layout/DefaultLayout.vue';
import EmptyLayout from '@/layout/EmptyLayout.vue';
import { useStore } from '@/shared/model/store/Store';
import { storeToRefs } from 'pinia';

const store = useStore();
const router = useRouter();
const route = useRoute();
const notifyCompanyConfirm = ref<ReturnType<typeof notifyCollapse>>();

const { isInitApp, isErrorInitApp } = storeToRefs(store);

const userStore = useUserStore();
const companyStatus = computed(() => userStore.company?.status);
const isPendingCompanyConfirm = computed<boolean>(() => companyStatus.value === CompanyStatus.REQUEST);
const isNotResolveActiveCompanyStatus = computed<boolean>(() => {
    if (!companyStatus.value) {
        return false;
    }
    return [CompanyStatus.INVITED, CompanyStatus.REQUEST].includes(companyStatus.value);
});

watch(isPendingCompanyConfirm, (isPending: boolean) => {
    if (isPending) {
        nextTick(() => {
            notifyCompanyConfirm.value = notifyCollapse({
                title: 'Ваша заявка отправлена',
                text: 'Зарегистрированная компания ожидает подтверждения'
            });
        });
    } else {
        notifyCompanyConfirm.value?.close();
    }
});

watch(isNotResolveActiveCompanyStatus, (isNotResolve: boolean) => {
    if (isNotResolve) {
        nextTick(() => {
            router.push({ name: 'company-profile' });
        });
    }
});

watch(
    route,
    () => {
        const title = route.meta?.title || route.matched[0]?.meta?.title;
        document.title = `${title} | ЛК Поставщика ЦВ Протек и Здравсити`;
    },
    { immediate: true }
);

const layout = computed(() => {
    const defaultLayout = userStore.isAuthorized ? DefaultLayout : EmptyLayout;
    return route.meta?.layout || defaultLayout;
});

const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
</script>
