import client from '@/shared/api/client';
import type { User, UserId, UserRelationships, UserStatus } from '@/shared/model/types/User';
import type { Company, CompanyId } from '@/shared/model/types/Company';
import * as JsonApiDefaultMapper from '@/shared/api/mapper/JsonApiDefaultMapper';
import * as CompanyMapper from '../mapper/Company';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import type { RelationRule } from '@/shared/api/types/JSON_API';
import { getRelationships } from '@/shared/api/utils';
import type { UserProfile, UserProfileId } from '@/shared/model/types/UserProfile';
import type { Role } from '@/shared/model/types/Role';
import { fileSaveAs } from '@/shared/model/utils';

export interface UserQueryParams extends Partial<ServerPagination> {
    id?: UserId;
    ids?: UserId[];
    companyId?: CompanyId;
    fullName?: string;
    email?: string;
    advertisingCampaignsModerator?: boolean;
    promoModerator?: boolean;
    isRequestFromSuppliers?: boolean;
    includes?: ('companies' | 'profile' | 'roles')[];
    status?: UserStatus;
}

const convertParams = (params: UserQueryParams): Record<string, string | number | boolean> => {
    const result: Record<string, string | number | boolean> = {};

    if (params.id) {
        result.id = params.id;
    }

    if (params.ids) {
        result['id[in]'] = String(params.ids);
    }

    if (params.companyId) {
        result['companies.id'] = params.companyId;
    }

    if (params.email) {
        result['email[like]'] = params.email;
    }

    if (Array.isArray(params.includes)) {
        result.include = params.includes.map(String).join(',');
    }

    if (params.fullName) {
        result['profile.fullName[like]'] = params.fullName;
    }

    if (typeof params.advertisingCampaignsModerator === 'boolean') {
        result['profile.advertisingCampaignsModerator'] = params.advertisingCampaignsModerator;
    }

    if (typeof params.promoModerator === 'boolean') {
        result['profile.promoModerator'] = params.promoModerator;
    }

    if (typeof params.isRequestFromSuppliers === 'boolean') {
        result['profile.isRequestFromSuppliers'] = params.isRequestFromSuppliers;
    }

    if (params.currentPage) {
        result.page = params.currentPage;
    }

    if (params.itemsPerPage) {
        result.limit = params.itemsPerPage;
    }

    if (params.status) {
        result.status = params.status;
    }

    return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUserRelationships = (data: any): UserRelationships => {
    const relationships = data.relationships || [];
    const rules: RelationRule[] = [
        {
            name: 'companies',
            rename: 'companyIds'
        },
        {
            name: 'links',
            rename: 'linkIds'
        },
        {
            name: 'notifications',
            rename: 'notificationIds'
        },
        {
            name: 'password',
            rename: 'passwordId',
            type: 'single'
        },
        {
            name: 'profile',
            rename: 'profileId',
            type: 'single'
        },
        {
            name: 'roles',
            rename: 'roleIds'
        }
    ];

    return {
        id: data.id,
        ...getRelationships(relationships, rules)
    } as UserRelationships;
};

export async function getUsers(
    params: UserQueryParams,
    abortController = new AbortController()
): Promise<{
    users: User[];
    companies: Company[];
    userProfiles: UserProfile[];
    userRelationships: UserRelationships[];
    roles: Role[];
    pagination: Pagination;
}> {
    const response = await client.get('/api/users', { params: convertParams(params), signal: abortController.signal });
    const users: User[] = [];
    const companies: Company[] = [];
    const userProfiles: UserProfile[] = [];
    const userRelationships: UserRelationships[] = [];
    const roles: Role[] = [];

    const data = Array.isArray(response.data.data) ? response.data.data : [];
    for (const item of data) {
        users.push(JsonApiDefaultMapper.toClient<User>(item));
        userRelationships.push(getUserRelationships(item));
    }
    const included = Array.isArray(response.data.included) ? response.data.included : [];
    for (const item of included) {
        if (item.type === 'companies') {
            companies.push(CompanyMapper.toClient(item));
        } else if (item.type === 'users-profiles') {
            userProfiles.push(JsonApiDefaultMapper.toClient<UserProfile>(item));
        } else if (item.type === 'roles') {
            roles.push(JsonApiDefaultMapper.toClient<Role>(item));
        }
    }
    return {
        users,
        companies,
        userProfiles,
        userRelationships,
        roles,
        pagination: response.data.meta
    };
}

export const updateUser = async function (userId: number, payload: Partial<User>) {
    await client.patch(`/api/users/${userId}`, payload);
};

export async function changePasswordByUserId(password: string, userId: UserId) {
    const payload = {
        password
    };

    await client.post(`/api/v1/users/${userId}/password`, payload);
}

export async function setUserRelationshipsToProfileId(userId: UserId, profileId: UserProfileId) {
    const payload = [
        {
            type: 'users-profiles',
            id: profileId
        }
    ];
    await client.patch(`/api/users/${userId}/relationships/users-profiles`, payload);
}

export async function setUserRelationshipsToCompanyId(userId: UserId, companyIds: CompanyId[]) {
    const payload = companyIds.map(id => ({ type: 'companies', id }));

    await client.patch(`/api/users/${userId}/relationships/companies`, payload);
}

export const createUser = async (user: { email: string; status: UserStatus }): Promise<User> => {
    const response = await client.post('/api/users', user);
    return JsonApiDefaultMapper.toClient<User>(response.data.data);
};

export async function downloadUserReport({ companyId, columns }: { companyId?: CompanyId; columns: string[] }) {
    const params = {
        ...(companyId ? { companyId } : null),
        columns: String(columns)
    };

    const response = await client({
        method: 'GET',
        url: '/api/v1/users/report',
        responseType: 'blob',
        params
    });

    const fileName = `Отчет по сотрудникам.xlsx`;
    fileSaveAs(response.data, fileName);
}
