/**
 * Преобразовать number значение в строку,
 * разделенную пробелом через каждый 3 символа,
 * с дробной частью до сотых. С возможностью отображения валюты RUB
 * @param value
 * @param symbol Показать символ валюты (₽)
 * @returns {string}
 * @example numberFormatCurrency(1000) => "1 000,00"
 * @example numberFormatCurrency(1000, true) => "1 000,00 ₽"
 */
export function numberFormatCurrency(value: number | null | undefined, symbol = false): string {
    if (!value && !Number.isFinite(value)) return '';

    return new Intl.NumberFormat('ru-RU', {
        style: symbol ? 'currency' : 'decimal',
        currency: 'RUB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currencyDisplay: 'symbol'
    }).format(value as number);
}
