/**
 * Преобразовать number значение в строку, разделенную пробелом через каждый 3 символа
 * @param value
 * @returns {string}
 * @example numberFormatDigits(1000) => "1 000"
 */
export function numberFormatDigits(value: number | null): string {
    const maximumFractionDigits = Number(value) < 10 ? 1 : 0;
    return new Intl.NumberFormat('ru-RU', { maximumFractionDigits }).format(Number(value));
}
