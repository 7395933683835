import type { CancelTokenSource } from 'axios';
import instance, { CanceledError } from 'axios';
import { NetworkError } from '@/errors/NetworkError';
import { UserAlreadyExists } from '@/errors/UserAlreadyExists';
import { LinkOpened } from '@/errors/LinkOpened';
import { Unauthorized } from '@/errors/Unauthorized';
import { ForbiddenError } from '@/errors/ForbiddenError';
import { checkProductError, ProductError } from '@/errors/ProductError';
import { HumanFriendlyError, isHumanFriendlyErrorType } from '@/errors/HumanFriendlyError';
import { isProductCategorySettingsError, ProductCategorySettingsError } from '@/errors/ProductCategorySettingsError';
import { getToken } from '@/shared/model/TokenService';
import { generateUUID } from '@/shared/model/utils';

let cancelToken: CancelTokenSource;

const axios = instance.create();

const logout = () => {
    cancelToken.cancel();
    const event = new CustomEvent('logout');
    document.dispatchEvent(event);
};

axios.interceptors.response.use(
    async response => {
        if (response.data?.jsonrpc && response.data?.result?.response?.statusCode === 401) {
            logout();
            throw new Unauthorized('Токен авторизации не валидный', response);
        }

        if (response.data?.jsonrpc) {
            const re = /^[User with email].+[alredy exists]$/;
            if (re.test(response.data?.result?.error) || response?.data?.type === 'USER_ALREADY_EXISTS') {
                throw new UserAlreadyExists('Пользователь с таким email уже существует', response);
            } else if (response.data?.result?.response?.error) {
                throw new NetworkError('Неизвестная ошибка сети', response);
            } else if (response.data?.result?.httpStatus === 500) {
                throw new NetworkError('Неизвестная ошибка сети', response);
            }
        }

        return response;
    },
    async error => {
        if (instance.isCancel(error)) {
            throw new CanceledError('Запрос отклонён');
        }

        const status = error.response?.status;
        if (status === 401) {
            logout();
            throw new Unauthorized('Токен авторизации не валидный', error.response);
        }

        if (status === 403) {
            throw new ForbiddenError('Нет доступа к данным', error.response);
        }

        let data: Record<string, never> = error.response?.data || {};
        if (error.config.responseType === 'blob') {
            data = JSON.parse(await error.response.data.text());
        }
        const errorName = data.errorName;
        const message = String(data.message);
        const errorType = data.type;

        if (errorName === 'LinksInvalidError' && message.endsWith('Status: opened')) {
            throw new LinkOpened('Ссылка уже была открыта', error.response);
        }

        if (checkProductError(errorType)) {
            throw new ProductError(error.response, errorType);
        }

        if (isProductCategorySettingsError(errorType)) {
            throw new ProductCategorySettingsError(error.response, errorType);
        }

        if (isHumanFriendlyErrorType(errorType)) {
            throw new HumanFriendlyError(error.response, errorType);
        }

        throw new NetworkError('Неизвестная ошибка сети', error.response);
    }
);
axios.interceptors.request.use(
    function (config) {
        cancelToken = instance.CancelToken.source();
        config.cancelToken = cancelToken.token;
        const token = getToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (!config.headers['X-Request-Id']) {
            config.headers['X-Request-Id'] = generateUUID();
        }

        return config;
    },
    function (error) {
        return Promise.resolve(error);
    }
);

export { axios };
