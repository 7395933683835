<template>
    <label class="taContainer" :class="commonClasses" :for="String(id)">
        <span :class="labelClasses">{{ label }}</span>
        <textarea
            :id="String(id)"
            ref="textareaEl"
            :value="modelValue"
            :autofocus="autofocus"
            :class="textareaClasses"
            :rows="rows"
            :disabled="disabled"
            :readonly="readonly"
            :placeholder="placeholder"
            data-testid="textarea"
            @input="onInput"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
        ></textarea>
    </label>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { generateUUID } from '@/shared/model/utils/generateUUID';

interface Props {
    /**
     * Аттрибут ID
     */
    id?: string | number;

    /**
     * Модель данных
     */
    modelValue?: string;

    /**
     * Кол-во строк по умолчанию
     */
    rows?: number | string;

    /**
     * Строка для подстановки описания поля ввода
     */
    label?: string;

    /**
     * Строка для подстановки заглушки в поля ввода
     */
    placeholder?: string;

    /**
     * Автоматический focus при первом рендеринге
     */
    autofocus?: boolean;

    /**
     * Флаг для отключения Browser иконки для растягивания textarea
     */
    noResize?: boolean;

    /**
     * Адаптировать по высоте под контент,
     * минимальная высота rows * rowHeight
     */
    autoGrow?: boolean;

    /**
     * Нужно для расчёта минимальной высоты если autoGrow = true,
     * в остальных случаях игнорируется
     */
    rowHeight?: number;

    /**
     * Блокировка состояния
     */
    disabled?: boolean;

    /**
     * Readonly состояние
     */
    readonly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    id: generateUUID(),
    modelValue: '',
    label: '',
    placeholder: '',
    autofocus: false,
    autoGrow: false,
    noResize: false,
    rows: 5,
    rowHeight: 18.4,
    disabled: false,
    readonly: false
});

const emit = defineEmits<{
    (e: 'update:modelValue', val: string): true;
    (e: 'input', val: string): true;
    (e: 'change', val: string): true;
    (e: 'focus'): true;
    (e: 'blur'): true;
}>();

const textareaEl = ref<HTMLInputElement>();
const isFocused = ref<boolean>(false);

const commonClasses = computed<Record<string, boolean>>(() => {
    return {
        focus: isFocused.value,
        disabled: !!props.disabled
    };
});

const labelClasses = computed<Record<string, boolean>>(() => {
    return {
        ...commonClasses.value,
        taLabel: true,
        active: Boolean(isFocused.value || props.placeholder || props.modelValue)
    };
});

const textareaClasses = computed<Record<string, boolean>>(() => {
    return {
        ...commonClasses.value,
        taTextarea: true,
        hasLabel: !!props.label,
        autoGrow: !!props.autoGrow,
        noResize: !!props.noResize
    };
});

const setTextareaHeight = (): void => {
    const textarea = textareaEl.value;

    if (!textarea || !props.autoGrow) {
        return;
    }

    textarea.style.height = '0';

    const height = textarea.scrollHeight;
    const minHeight = parseInt(String(props.rows), 10) * Number(props.rowHeight);
    // See: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VTextarea/VTextarea.ts#L81
    // This has to be done ASAP, waiting for Vue
    // to update the DOM causes ugly layout jumping
    textarea.style.height = Math.max(minHeight, height) + 'px';
};

const onInput = (event: Event) => {
    const textarea: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
    emit('input', textarea.value);
    emit('update:modelValue', textarea.value);
};

const onChange = (event: Event) => {
    emit('change', (event.target as HTMLTextAreaElement).value);
};

const onFocus = () => {
    isFocused.value = true;
    textareaEl.value?.focus();
    emit('focus');
};

const onBlur = () => {
    isFocused.value = false;
    emit('blur');
};

defineExpose({ onFocus });

watch(() => props.modelValue, setTextareaHeight);

onMounted(setTextareaHeight);
</script>

<style lang="scss" scoped>
.taContainer {
    --ta-text-color: var(--text-color);

    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    background-color: var(--ta-background-color, white);
    border: 1px solid var(--ta-border-color, #eef1f7);
    border-radius: var(--ta-border-radius, 8px);

    &.focus {
        border-color: var(--border);
    }

    &.success {
        --ta-text-color: var(--success);
        border-color: var(--success);
    }

    &.error {
        border-color: var(--danger);
    }

    &.disabled {
        background-color: var(--disable-bg-color);
    }
}

.validation-field {
    &.success .taContainer {
        --ta-text-color: var(--success);
        border-color: var(--success);
    }

    &.error .taContainer {
        border-color: var(--danger);
    }
}

.taLabel {
    position: absolute;
    top: 11px;
    left: 16px;
    color: var(--ta-placeholder, #a7aeb8);
    font-size: 16px;
    transition: all 0.2s ease;
    background: white;

    &.active {
        font-size: 12px;
        color: var(--ta-text-color);
    }

    &.disabled {
        background-color: transparent;
    }

    @at-root.error & {
        color: var(--danger);
    }
}

.taTextarea {
    width: 100%;
    margin-top: 11px;
    padding: 0 16px 11px;
    border: 0;
    text-align: left;
    color: var(--ta-text-color);
    font-size: 16px;
    resize: vertical;

    &::placeholder {
        color: var(--ta-placeholder, #a7aeb8);
    }

    &:focus-visible {
        outline: none;
    }

    &.hasLabel {
        margin-top: 30px;
    }

    &.noResize {
        resize: none;
    }

    &.autoGrow {
        height: auto;
        resize: none;
        overflow: hidden;
    }

    &.taHidden {
        position: relative;
        z-index: -99999;
        opacity: 0 !important;
        visibility: hidden !important;
    }

    &.disabled {
        background-color: var(--disable-bg-color);
    }
}
</style>
