import * as Sentry from '@sentry/vue';
import { MODE, SENTRY_DSN } from '@/shared/config/env';
import { NetworkError } from '@/errors/NetworkError';
import type { Router } from 'vue-router';
import { CanceledError } from 'axios';
import type { App } from 'vue';

export interface sentry {
    init: (app: App, router: Router) => void;
    captureMessage: typeof Sentry.captureMessage;
    captureEvent: typeof Sentry.captureEvent;
    captureException: typeof Sentry.captureException;
    setUser: (user: { id: number; email?: string }) => void;
    unsetUser: () => void;
}

// DOC: https://docs.sentry.io/platforms/javascript/guides/vue/
const sentry: sentry = {
    init(app: App, router: Router) {
        if (!SENTRY_DSN) {
            return;
        }

        Sentry.init({
            app,
            dsn: SENTRY_DSN,
            environment: MODE,
            integrations: [
                // DOC: https://docs.sentry.io/platforms/javascript/configuration/integrations/
                Sentry.browserTracingIntegration({ router }),
                Sentry.replayIntegration(),
                Sentry.httpClientIntegration(),
                Sentry.sessionTimingIntegration(),
                Sentry.extraErrorDataIntegration(),
                Sentry.reportingObserverIntegration()
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // DOC: https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/
            attachProps: true,
            logErrors: true,
            trackComponents: true,

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,

            beforeSend(event, hint) {
                const error = hint.originalException;

                if (error instanceof Error) {
                    handlerFailedFetchModule(error, window.location.href.toString());
                }

                // SEE: https://docs.sentry.io/platforms/javascript/configuration/filtering/
                const ignoreErrorClasses = [NetworkError, CanceledError];
                // можно не только по классу ошибки, но и по тексту например
                if (ignoreErrorClasses.some(c => error instanceof c)) {
                    return null;
                }

                return event;
            },
            denyUrls: [
                // Google Adsense
                /pagead\/js/i,
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                // Yandex.Metrika
                /mc\.yandex\.ru\/metrika\/tag\.js/i
            ]
        });
    },
    captureMessage: Sentry.captureMessage,
    captureEvent: Sentry.captureEvent,
    captureException: Sentry.captureException,
    setUser(user) {
        Sentry.getCurrentScope().setUser({
            id: user.id,
            email: user.email
        });
    },
    unsetUser() {
        Sentry.getCurrentScope().setUser(null);
    }
};

const countTryReloadPage = {
    _key: 'SENTRY_COUNT_RELOAD_PAGE',
    get() {
        return Number(sessionStorage.getItem(this._key));
    },
    set(value: number) {
        sessionStorage.setItem(this._key, String(value));
    }
};

const LIMIT_TRY_RELOAD = 5;

export function handlerFailedFetchModule(error: Error, url: string) {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
        const numberTry = countTryReloadPage.get();
        if (numberTry < LIMIT_TRY_RELOAD) {
            countTryReloadPage.set(numberTry + 1);
        } else {
            sentry.captureException('Limit on page reloads has been reached.', {
                extra: {
                    originalError: error,
                    message: error.message
                }
            });
            alert(
                'Ошибка! Для продолжения работы нужно очистить кэш в браузере или открыть сайт в приватном режиме браузера.'
            );
            return;
        }
        sentry.captureMessage('Triggered automatic reload after failed to fetch dynamically imported module.', {
            extra: {
                originalError: error,
                message: error.message
            }
        });
        window.location.href = url;
    }
}

export default sentry;
