import client from '../client';
import type { Company, CompanyAdvId, CompanyId } from '@/shared/model/types/Company';
import type { CompanyManager } from '@/shared/model/types/CompanyManager';
import type { CompanyAddress } from '@/shared/model/types/CompanyAddress';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import type { CompanyBankAccount } from '@/shared/model/types/CompanyBankAccount';
import type { CompanyForm } from '@/shared/model/types/CompanyForm';
import type { CompanyDocument } from '@/shared/model/types/CompanyDocument';
import type { CompanyContract } from '@/shared/model/types/CompanyContract';
import { dayjs } from '@/shared/lib/dayjs';
import { fileSaveAs } from '@/shared/model/utils';
import type { CompanyStatus, CompanyProtekId } from '@/shared/model/types/Company';
import type { UserId } from '@/shared/model/types/User';
import type { Staff } from '@/entities/Staff';
import type { WorkerId } from '@/entities/Staff/model/Staff';
import { omit } from 'lodash';

export enum CompanyRelation {
    MANAGERS = 'managers',
    ADDRESSES = 'addresses',
    BANK_ACCOUNTS = 'bankAccounts',
    FORM = 'form',
    DOCUMENTS = 'documents',
    CONTRACTS = 'contracts',
    COMMERCIAL_MANAGERS = 'commercialManagers',
    USERS_COUNT = 'usersCount',
    PROTEK_SUPPLIERS = 'protekSuppliers'
}

export interface CompanyRestQueryParams extends Partial<ServerPagination> {
    ids?: CompanyId[] | null;
    userIds?: UserId[] | null;
    wabcCodes?: CompanyProtekId[] | null;
    statuses?: CompanyStatus[] | null;
    name?: string | null;
    inns?: string[] | null;
    advertiserIds?: CompanyAdvId[] | null;
    commercialManagerIds?: number[] | null;
    relations?: CompanyRelation[] | null;
}

const convertRestParams = (params: CompanyRestQueryParams) => {
    return Object.entries(params).reduce<Record<string, unknown>>((acc, [name, value]) => {
        acc[name] = Array.isArray(value) ? value.join(',') : value;
        return acc;
    }, {});
};

export const getCompanies = async (
    params: CompanyRestQueryParams = {},
    abortController = new AbortController()
): Promise<{
    companies: Company[];
    commercialManagers: Staff[];
    addresses: CompanyAddress[];
    bankAccounts: CompanyBankAccount[];
    documents: CompanyDocument[];
    contracts: CompanyContract[];
    managers: CompanyManager[];
    pagination: Pagination;
}> => {
    const { itemsPerPage: limit = 100, currentPage: page = 1, ...otherParams } = params;
    const response = await client.get('/api/v1/companies', {
        params: {
            ...convertRestParams(otherParams),
            limit,
            page
        },
        signal: abortController.signal
    });

    return {
        companies: response.data.data,
        pagination: response.data.meta,
        commercialManagers: [],
        addresses: [],
        managers: [],
        bankAccounts: [],
        documents: [],
        contracts: [],
        ...response.data.relations
    };
};

export const getCompany = async (
    id: CompanyId,
    params: Omit<CompanyRestQueryParams, 'ids' | 'itemsPerPage' | 'currentPage'> = {},
    abortController = new AbortController()
): Promise<{
    company: Company;
    commercialManagers: Staff[];
    addresses: CompanyAddress[];
    bankAccounts: CompanyBankAccount[];
    form?: CompanyForm;
    documents: CompanyDocument[];
    contracts: CompanyContract[];
    managers: CompanyManager[];
}> => {
    const response = await client.get(`/api/v1/companies/${id}`, {
        params: convertRestParams(params),
        signal: abortController.signal
    });

    let form: CompanyForm | undefined;

    if ('form' in response.data.data) {
        form = response.data.data.form;
        delete response.data.data.form;
    }

    return {
        company: response.data.data,
        form,
        pagination: response.data.meta,
        commercialManagers: [],
        addresses: [],
        managers: [],
        bankAccounts: [],
        documents: [],
        contracts: [],
        ...response.data.relations
    };
};

export async function updateCompany(company: Partial<Company> & { id: CompanyId }): Promise<void> {
    await client.patch(`/api/v1/companies/${company.id}`, omit(company, 'id'));
}

export async function createCompany(
    company: Partial<Omit<Company, 'id' | 'status'>> & { commercialManagerCdColl?: WorkerId | null }
): Promise<Company> {
    // у некоторых компаний вместо null стоит 0, а наш бек пропускает только положительные числа. 0 не положительное число
    const systemId = company.systemId === 0 ? null : company.systemId;
    const response = await client.post(`/api/v1/companies`, {
        ...company,
        systemId
    });
    return response.data;
}

export const exportCompanies = async (params: {
    ids?: CompanyId[];
    status: 'active' | 'request' | 'invited' | 'blocked' | 'unconfirmed';
    inn?: string;
    columns: string[];
}) => {
    const serverPayload: {
        id?: string;
        status: string;
        inn?: string;
        columns?: string;
    } = {
        status: params.status
    };
    if (Array.isArray(params.ids)) {
        serverPayload.id = params.ids.join(',');
    }
    if (params.columns.length) {
        serverPayload.columns = params.columns.join(',');
    }
    if (params.inn) {
        serverPayload.inn = params.inn;
    }
    const response = await client({
        method: 'get',
        url: `/api/v1/companies/report`,
        responseType: 'blob',
        params: serverPayload
    });
    const fileName = `Список поставщиков ${dayjs().format('DD.MM.YYYY HH.mm')}.xlsx`;
    fileSaveAs(response.data, fileName);
};
