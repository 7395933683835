import client from '@/shared/api/client';
import { generateUUID, logger } from '@/shared/model/utils';
import { NetworkError } from '@/errors/NetworkError';
import { cloneDeep } from 'lodash';
import { dayjs } from '@/shared/lib/dayjs';
import {
    type ActionException,
    type ActionLine,
    ActionPromoBonusPackageSubTypeAction,
    ActionPromoChannel,
    type ActionPromoDetail,
    type ActionPromoId,
    ActionPromoStatus,
    type ActionPromoTable,
    ActionPromoTypeAction,
    BonusPackageBonusMetric,
    SaleInPriceBonusMetric
} from '../model/types';
import { type Medicine, type MedicineId, MedicineService } from '@/entities/Medicine';
import type { dateISO } from '@/shared/model/types/Kernel';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import type { ObjectSchema } from 'yup';
import * as yup from 'yup';
import sentry from '@/shared/lib/sentry/sentry';
import type { BranchId } from '@/entities/Branch/model/Branch';
import { type SegmentId } from '@/entities/Segment/model';

const NAMES: [string, keyof ActionPromoDetail][] = [
    ['acceptChangesDate', 'acceptChangesDate'],
    ['actionDeleteDate', 'actionDeleteDate'],
    ['action_exceptions', 'actionExceptions'],
    ['action_lines', 'actionLines'],
    ['action_name', 'actionName'],
    ['base_period_s', 'basePeriodStart'],
    ['base_period_e', 'basePeriodEnd'],
    ['channel', 'channel'],
    ['sdate', 'startDate'],
    ['edate', 'endDate'],
    ['id_action_promo', 'idActionPromo'],
    ['id_action', 'idAction'],
    ['isActive', 'isActive'],
    ['link_external_file', 'linkExternalFile'],
    ['manager_comment', 'managerComment'],
    ['message', 'message'],
    ['note', 'note'],
    ['note_expanded', 'noteExpanded'],
    ['provider_code', 'providerCode'],
    ['start_action', 'startAction'],
    ['status', 'status'],
    ['subtype_action', 'subTypeAction'],
    ['type_action', 'typeAction']
];

const ACTION_LINE_NAMES: [string, keyof ActionLine][] = [
    ['average_sale_quantity', 'averageSaleQuantity'],
    ['average_sale_sum', 'averageSaleSum'],
    ['bonus_item_code', 'bonusItemCode'],
    ['bonus_item_discount', 'bonusItemDiscount'],
    ['bonus_item_nds', 'bonusItemNds'],
    ['bonus_item_price', 'bonusItemPrice'],
    ['bonus_metrics', 'bonusMetrics'],
    ['bonus_value', 'bonusValue'],
    ['fact_quantity', 'factQuantity'],
    ['fact_sum', 'factSum'],
    ['growth_perc', 'growthPerc'],
    ['item_code', 'itemCode'],
    ['item_discount', 'itemDiscount'],
    ['item_metrics', 'itemMetrics'],
    ['item_multiplicity', 'itemMultiplicity'],
    ['item_price', 'itemPrice'],
    ['nun_begin', 'nunBegin'],
    ['nun_end', 'nunEnd'],
    ['provider_code_assortment', 'providerCodeAssortment'],
    ['purchase_amount_e', 'purchaseAmountEnd'],
    ['purchase_amount_s', 'purchaseAmountStart'],
    ['size_adhesions', 'sizeAdhesions'],
    ['size_box', 'sizeBox']
];

const ACTION_EXCEPTION_NAMES: [string, keyof ActionException][] = [
    ['branch_part', 'branchPart'],
    ['client_exc', 'clientExc'],
    ['client_part', 'clientPart'],
    ['exclusion_flag', 'exclusionFlag'],
    ['id_action', 'idAction'],
    ['segment_part', 'segmentPart'],
    ['sp_participants', 'spParticipants']
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toServer = (_data: Partial<ActionPromoDetail>): any => {
    const data: Partial<ActionPromoDetail> = cloneDeep(_data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = cloneDeep(data);

    for (const [serverName, clientName] of NAMES) {
        if (Object.prototype.hasOwnProperty.call(data, clientName)) {
            response[serverName] = data[clientName];
            if (serverName !== clientName) {
                delete response[clientName];
            }
        }
    }

    if (data.actionLines) {
        for (const [i, actionLine] of Object.entries(data.actionLines)) {
            for (const [serverName, clientName] of ACTION_LINE_NAMES) {
                if (Object.prototype.hasOwnProperty.call(actionLine, clientName)) {
                    response['action_lines'][i][serverName] = actionLine[clientName];
                    delete response['action_lines'][i][clientName];
                }
            }
        }
    }

    if (data.actionExceptions) {
        for (const [i, actionException] of Object.entries(data.actionExceptions)) {
            for (const [serverName, clientName] of ACTION_EXCEPTION_NAMES) {
                if (Object.prototype.hasOwnProperty.call(actionException, clientName)) {
                    response['action_exceptions'][i][serverName] = actionException[clientName];
                    if (serverName === 'exclusion_flag') {
                        response['action_exceptions'][i][serverName] = response['action_exceptions'][i][serverName]
                            ? 1
                            : 0;
                    }

                    delete response['action_exceptions'][i][clientName];
                }
            }
        }
    }

    for (const dateKey of ['sdate', 'edate', 'base_period_s', 'base_period_e']) {
        if (response[dateKey]) {
            response[dateKey] = dayjs(response[dateKey]).format('DD.MM.YYYY');
        }
    }

    if (response['provider_code']) {
        response['provider_code'] = String(response['provider_code']);
    }

    if (response['isActive']) {
        response['isActive'] = response['isActive'] ? 1 : 0;
    }

    return response;
};

const getMedicineNames = async (ids: MedicineId[]): Promise<string> => {
    let medications: Medicine[] = [];

    try {
        const response = await MedicineService.getMedications({
            medicineCodes: ids,
            pagination: { currentPage: 1, itemsPerPage: ids.length }
        });
        medications = response.data;
    } catch (error) {
        logger.error(error);
    }

    return ids
        .map(id => {
            const medicine = medications.find(m => m.medicineCode === id);
            return medicine?.currentWabcName ?? `medicine_code ${id}`;
        })
        .join(',');
};

const generateDescription = async (actionPromo: Partial<ActionPromoDetail>) => {
    let description = `<p>`;

    if (actionPromo.typeAction === ActionPromoTypeAction.BONUS_PACKAGE) {
        const actionLines = actionPromo.actionLines ?? [];
        const bonusItemsIds = actionLines
            .filter(item => item.bonusItemCode !== null)
            .map(item => item.bonusItemCode) as MedicineId[];
        if (bonusItemsIds.length > 3) {
            description += 'Ассортимент ' + bonusItemsIds.length;
        } else {
            // подставлять настоящие названия товаров
            description += await getMedicineNames(bonusItemsIds);
        }
    }

    if (actionPromo.typeAction === ActionPromoTypeAction.SALE_IN_PRICE) {
        const actionLines = actionPromo.actionLines ?? [];
        const bonusItemsIds = actionLines
            .filter(item => item.bonusItemCode !== null)
            .map(item => item.bonusItemCode) as MedicineId[];
        if (bonusItemsIds.length > 3) {
            description += '\r\n  ' + bonusItemsIds.length + ' препаратов';
        } else {
            // подставлять настоящие названия товаров
            description += await getMedicineNames(bonusItemsIds);
        }
    }

    description += '</p> \r\n<p><font size="2.5" color="red"><b>';

    if (actionPromo.typeAction === ActionPromoTypeAction.BONUS_PACKAGE) {
        description += 'БОНУСНАЯ УПАКОВКА';

        if (actionPromo.subTypeAction === ActionPromoBonusPackageSubTypeAction.BY_RUBLE) {
            description += ' за сумму заказа';
        }
    }

    if (actionPromo.typeAction === ActionPromoTypeAction.SALE_IN_PRICE) {
        const actionLines = actionPromo.actionLines ?? [];
        const sales = actionLines.map(item => item.bonusValue);
        const maxSale = Math.max(...sales);
        description += 'СКИДКА В ЦЕНЕ до ' + maxSale;
    }

    description += `</b></font></p>`;

    return description;
};

export const actionPromoCreate = async (
    actionPromo: Partial<ActionPromoDetail>,
    actionPromoId?: ActionPromoId
): Promise<{ actionId: ActionPromoId }> => {
    // DOC: docs/actonPromoExceptions.md
    const BRANCH_OMTS = 227;
    const BRANCH_OPTOM = 189;
    const SEGMENT_OOP = 5;

    const actionExceptions = actionPromo.actionExceptions!.reduce(
        (acc, actionException) => {
            const parts = ['branchPart', 'segmentPart', 'clientPart'] as const;

            for (const part of parts) {
                const id = actionException.exclusionFlag ? 1 : 0;
                acc[id][part].push(...actionException[part]);
            }

            return acc;
        },
        [
            // exclusion_flag = 0
            {
                branchPart: [],
                clientPart: [],
                segmentPart: []
            },
            // exclusion_flag = 1
            {
                branchPart: [BRANCH_OMTS],
                clientPart: [],
                segmentPart: []
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as [Record<string, any>, Record<string, any>]
    );

    if (!actionExceptions[0].segmentPart.includes(SEGMENT_OOP)) {
        actionExceptions[1].branchPart.push(BRANCH_OPTOM);
    }

    actionPromo.actionExceptions = [
        {
            exclusionFlag: false,
            branchPart: Array.from(new Set(actionExceptions[0].branchPart)),
            clientPart: Array.from(new Set(actionExceptions[0].clientPart)),
            segmentPart: Array.from(new Set(actionExceptions[0].segmentPart))
        },
        {
            exclusionFlag: true,
            branchPart: Array.from(new Set(actionExceptions[1].branchPart)),
            clientPart: Array.from(new Set(actionExceptions[1].clientPart)),
            segmentPart: Array.from(new Set(actionExceptions[1].segmentPart))
        }
    ] as ActionException[];

    const data = toServer(actionPromo);

    if (actionPromoId) {
        data['id_action'] = actionPromoId;
    }

    data.note = await generateDescription(actionPromo);

    const payload = {
        start_from: '0',
        count: '1',
        cache: '0',
        correlation_id: generateUUID(),
        action_promo: data
    };

    const response = await client.post('/api/proxy/service/promo-create', payload);
    if (response.data.status !== '0') {
        throw new NetworkError('Ответ от протек пришел с неизвестным статусом', response);
    }

    return {
        actionId: Number(response.data.correlation_id) as ActionPromoId
    };
};

export const actionPromoUpdate = async (
    actionPromo: Partial<ActionPromoDetail>,
    actionPromoId: ActionPromoId
): Promise<{ actionId: ActionPromoId }> => {
    return actionPromoCreate(actionPromo, actionPromoId);
};

interface ActionPromosParams {
    ids?: ActionPromoId[];
    typeActions?: number[];
    actionStatuses?: number[];
    supplierIds?: number[];
    startDate: null | dateISO;
    endDate: null | dateISO;
    medicineCodes?: number[];
}

const actionPromoTableItemSchema: ObjectSchema<ActionPromoTable> = yup
    .object()
    .camelCase()
    .shape({
        acceptChangesDate: yup.string().toDate('DD.MM.YYYY HH:mm:ss').nullable().defined(),
        actionName: yup.string().defined(),
        actionStartDateInSk2: yup.string().nullable().defined(),
        actionStatus: yup.number().defined(),
        actionType: yup.number().defined(),
        actionSubtype: yup.number().nullable().defined(),
        compensationEr: yup.number().nullable().defined(),
        compensationPrice: yup.number().nullable().defined(),
        compensationSum: yup.number().nullable().defined(),
        creationDate: yup.string().toDate('DD.MM.YYYY HH:mm:ss').defined(),
        endDate: yup.string().toDate('DD.MM.YYYY').required(),
        idAction: yup.number<ActionPromoId>().required(),
        idRow: yup.number().required(),
        linkExternalFile: yup.string().nullable().defined(),
        managerComment: yup.string().nullable().defined(),
        messageSendingFlag: yup.string().nullable().defined(),
        noteExpanded: yup.string().nullable().defined(),
        startDate: yup.string().toDate('DD.MM.YYYY').required(),
        supplierCode: yup.number().defined(),
        supplierFullName: yup.string().defined(),
        supplierInn: yup.string().defined(),
        supplierName: yup.string().defined(),
        userId: yup.number().nullable().defined()
    });
const actionPromoTableSchema = yup.array().of(actionPromoTableItemSchema).defined();

export const getActionPromoList = async (payload: {
    params: ActionPromosParams;
    pagination?: ServerPagination;
}): Promise<{ data: ActionPromoTable[]; pagination: Pagination }> => {
    let startFrom = 0;
    const currentPage = payload.pagination?.currentPage ?? 1;
    const itemsPerPage = payload.pagination?.itemsPerPage ?? 10;
    if (payload.pagination?.currentPage) {
        startFrom = (currentPage - 1) * itemsPerPage;
    }
    const serverPayload = {
        id_action: payload.params?.ids ?? [],
        type_action: payload.params?.typeActions ?? [],
        action_status: payload.params?.actionStatuses ?? [],
        supplier_code: payload.params?.supplierIds ?? [],
        start_date: dayjs(payload.params.startDate).isValid()
            ? dayjs(payload.params.startDate).format('DD.MM.YYYY')
            : '',
        end_date: dayjs(payload.params.endDate).isValid() ? dayjs(payload.params.endDate).format('DD.MM.YYYY') : '',
        medicine_code: payload.params?.medicineCodes,
        start_from: String(startFrom),
        count: String(itemsPerPage),
        cache: '0',
        correlation_id: generateUUID()
    };
    const url = `/api/proxy/report/promo-action-info-rep1`;
    const response = await client.post(url, serverPayload);
    if (response.data.status !== '0') {
        throw new NetworkError('Ответ от протек пришел с неизвестным статусом', response);
    }
    let data: ActionPromoTable[] = [];
    if (Array.isArray(response.data?.data?.spActionInfoRep1)) {
        await actionPromoTableSchema
            .validate(response.data.data.spActionInfoRep1, {
                abortEarly: false,
                stripUnknown: true
            })
            .catch(error => {
                const message = `Не валидный ответ ${url}`;
                sentry.captureMessage(message);
                sentry.captureException(error);
                logger.warn(message);
                logger.error(error);
            });

        data = actionPromoTableSchema.cast(response.data.data.spActionInfoRep1, {
            stripUnknown: true,
            assert: false
        });
    }

    const totalItems = Number(response.data.total_record_count);
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pagination: Pagination = {
        totalItems,
        currentPageItemsCount: data.length,
        itemsPerPage,
        totalPages,
        currentPage
    };
    return {
        data,
        pagination
    };
};

export const preCalcActionPromo = async (actionId: ActionPromoId) => {
    const serverPayload = {
        id_action: actionId,
        action_approve: 'ACCEPT_CHANGES',

        manager_comment: null,
        start_from: 0,
        count: 1,
        cache: 0,
        correlation_id: generateUUID()
    };
    await client.post(`/api/proxy/service/promo-approve`, serverPayload);
};

export const approveActionPromo = async (actionId: ActionPromoId) => {
    const serverPayload = {
        id_action: actionId,
        action_approve: 'APPROVE_ACTION',

        manager_comment: null,
        start_from: 0,
        count: 1,
        cache: 0,
        correlation_id: generateUUID()
    };
    await client.post(`/api/proxy/service/promo-approve`, serverPayload);
};

export const removeActionPromo = async (actionId: ActionPromoId) => {
    const serverPayload = {
        id_action: actionId,
        action_approve: 'CANCEL_ACTION',

        manager_comment: null,
        start_from: 0,
        count: 1,
        cache: 0,
        correlation_id: generateUUID()
    };
    await client.post(`/api/proxy/service/promo-approve`, serverPayload);
};

export const approveByManagerActionPromo = async (actionId: ActionPromoId) => {
    const serverPayload = {
        id_action: actionId,
        action_approve: 'APPROVE_ACTION_MANAGER',

        manager_comment: null,
        start_from: 0,
        count: 1,
        cache: 0,
        correlation_id: generateUUID()
    };
    await client.post(`/api/proxy/service/promo-approve`, serverPayload);
};

export const cancelByManagerActionPromo = async (actionId: ActionPromoId, managerComment: string) => {
    const serverPayload = {
        id_action: actionId,
        action_approve: 'CANCEL_ACCEPTED_CHANGES',
        manager_comment: managerComment,
        start_from: 0,
        count: 1,
        cache: 0,
        correlation_id: generateUUID()
    };
    await client.post(`/api/proxy/service/promo-approve`, serverPayload);
};

export const declineActionPromo = async (actionId: ActionPromoId) => {
    const serverPayload = {
        id_action: actionId,
        action_approve: 'CANCEL_ACCEPTED_CHANGES',

        manager_comment: null,
        start_from: 0,
        count: 1,
        cache: 0,
        correlation_id: generateUUID()
    };
    await client.post(`/api/proxy/service/promo-approve`, serverPayload);
};

const actionExceptionSchema: ObjectSchema<ActionException> = yup.object().shape({
    clientExc: yup.number().nullable().defined(),
    branchPart: yup.array().of(yup.number<BranchId>().required()).required(),
    clientPart: yup.array().of(yup.string().required()).required(),
    segmentPart: yup.array().of(yup.number<SegmentId>().required()).required(),
    exclusionFlag: yup.boolean().required(),
    idAction: yup.number().nullable().defined(),
    spParticipants: yup.number().nullable().defined()
});

const actionLineSchema: ObjectSchema<ActionLine> = yup.object().shape({
    averageSaleQuantity: yup.number().nullable().defined(),
    averageSaleSum: yup.number().nullable().defined(),
    bonusItemCode: yup.number<MedicineId>().nullable().defined(),
    bonusItemDiscount: yup.number().nullable().defined(),
    bonusItemNds: yup.number().nullable().defined(),
    bonusItemPrice: yup.number().nullable().defined(),
    bonusMetrics: yup
        .mixed<SaleInPriceBonusMetric | BonusPackageBonusMetric>()
        .oneOf([...Object.values(SaleInPriceBonusMetric), ...Object.values(BonusPackageBonusMetric)] as readonly (
            | SaleInPriceBonusMetric
            | BonusPackageBonusMetric
        )[])
        .required(),
    bonusValue: yup.number().required(),
    factQuantity: yup.number().nullable().defined(),
    factSum: yup.number().nullable().defined(),
    growthPerc: yup.number().nullable().defined(),
    itemCode: yup.array().of(yup.number<MedicineId>().required()).required(),
    itemDiscount: yup.number().nullable().defined(),
    itemMetrics: yup.string().oneOf(['Упаковки', 'без НДС']).required(),
    itemMultiplicity: yup.number().nullable().defined(),
    itemPrice: yup.number().nullable().defined(),
    nunBegin: yup.number().nullable().defined(),
    nunEnd: yup.number().nullable().defined(),
    providerCodeAssortment: yup.number().nullable().defined(),
    purchaseAmountEnd: yup.number().nullable().defined(),
    purchaseAmountStart: yup.number().nullable().defined(),
    sizeAdhesions: yup.number().nullable().defined(),
    sizeBox: yup.number().nullable().defined()
});

const actionPromoReportBonusPackageSchema: ObjectSchema<ActionPromoDetail> = yup
    .object()
    .transform(data => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any = {};
        for (const [serverName, clientName] of NAMES) {
            if (clientName === 'isActive') {
                data[serverName] = Boolean(data[serverName]);
            }
            if (clientName === 'providerCode') {
                data[serverName] = Number(data[serverName]);
            }
            result[clientName] = cloneDeep(data[serverName]);
        }
        if (!Array.isArray(result.actionExceptions)) {
            result.actionExceptions = [];
        }
        for (let i = 0; i < result.actionExceptions.length; i++) {
            result.actionExceptions[i] = {};
            for (const [serverName, clientName] of ACTION_EXCEPTION_NAMES) {
                if (serverName === 'exclusion_flag') {
                    data['action_exceptions'][i][serverName] = Boolean(data['action_exceptions'][i][serverName]);
                }
                result.actionExceptions[i][clientName] = data['action_exceptions'][i][serverName];
            }
        }

        if (!Array.isArray(result.actionLines)) {
            result.actionLines = [];
        }
        for (let i = 0; i < result.actionLines.length; i++) {
            result.actionLines[i] = {};
            for (const [serverName, clientName] of ACTION_LINE_NAMES) {
                result.actionLines[i][clientName] = data['action_lines'][i][serverName];
            }
        }
        return result as ActionPromoDetail;
    })
    .shape({
        acceptChangesDate: yup.number().nullable(),
        actionDeleteDate: yup.number().nullable(),
        actionExceptions: yup.array().of(actionExceptionSchema).required(),
        actionLines: yup.array().of(actionLineSchema).required(),
        actionName: yup.string().required(),
        basePeriodEnd: yup.number().nullable().defined(),
        basePeriodStart: yup.number().nullable().defined(),
        channel: yup
            .mixed<ActionPromoChannel>((input): input is ActionPromoChannel => input === ActionPromoChannel.B2B)
            .required(),
        startDate: yup.string().toDate('DD.MM.YYYY').required(),
        endDate: yup.string().toDate('DD.MM.YYYY').required(),
        idActionPromo: yup.number().nullable().defined(),
        idAction: yup.number<ActionPromoId>().required(),
        isActive: yup.boolean().required(),
        linkExternalFile: yup.string().nullable().defined(),
        managerComment: yup.string().nullable().defined(),
        message: yup.string().nullable().defined(),
        note: yup.string().required(),
        noteExpanded: yup.string().nullable().defined(),
        providerCode: yup.number().required(),
        startAction: yup.number().nullable().defined(),
        status: yup
            .mixed<ActionPromoStatus>((input): input is ActionPromoStatus =>
                Object.values(ActionPromoStatus).includes(input)
            )
            .required(),
        subTypeAction: yup
            .mixed<ActionPromoBonusPackageSubTypeAction>(
                (input): input is ActionPromoBonusPackageSubTypeAction =>
                    Object.values(ActionPromoBonusPackageSubTypeAction).includes(input) || input === null
            )
            .nullable()
            .defined(),
        typeAction: yup
            .mixed<ActionPromoTypeAction>((input): input is ActionPromoTypeAction =>
                Object.values(ActionPromoTypeAction).includes(input)
            )
            .required()
    });

export const getActionPromo = async (id: ActionPromoId): Promise<ActionPromoDetail> => {
    const url = `/api/proxy/service/promo-action-info-details/${id}`;
    const response = await client.post(url);

    if (response.data.status !== '0') {
        throw new NetworkError('Ответ от протек пришел с неизвестным статусом', response);
    }

    await actionPromoReportBonusPackageSchema
        .validate(response.data.data.action_promo, {
            abortEarly: false,
            stripUnknown: true
        })
        .catch(error => {
            const message = `Не валидный ответ ${url}`;
            sentry.captureMessage(message);
            sentry.captureException(error);
            logger.warn(message);
            logger.error(error);
        });

    return actionPromoReportBonusPackageSchema.cast(response.data.data.action_promo, {
        stripUnknown: true,
        assert: false
    });
};
