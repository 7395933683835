import { addMethod, type AnyObject, date, type Maybe, setLocale, string, type TestConfig } from 'yup';
import { dayjs } from '@/shared/lib/dayjs';
import { DATE_ISO_FORMAT, DATE_ISO_FORMAT_WITHOUT_TZ } from '@/shared/config/constants';

setLocale({
    mixed: {
        required: 'Обязательное поле',
        oneOf: 'Одно из полей должно быть выбрано',
        notOneOf: 'Не одно из полей должно быть выбрано',
        notType: ({ type }) => {
            if (type === 'number') {
                return 'Поле должно быть числом';
            } else {
                return `Поле должно быть типа ${type}`;
            }
        }
    },
    number: {
        min: ({ min }) => `Поле должно быть больше или равно ${min}`,
        max: ({ max }) => `Поле должно быть меньше или равно ${max}`
    },
    string: {
        min: ({ min }) => `Поле должно быть не короче ${min} символов`,
        max: ({ max }) => `Поле должно быть не длиннее ${max} символов`
    },
    date: {
        min: ({ min }) => `Поле должно быть не раньше ${min}`,
        max: ({ max }) => `Поле должно быть не позже ${max}`
    }
});

const lessThanTodayOrEqual = (
    message = 'Превышает текущий день'
): TestConfig<Date | string | undefined, Maybe<AnyObject>> => ({
    name: 'lessThanTodayOrEqual',
    test: (value: Date | string | undefined) => {
        const date = dayjs(value);
        return date.isValid() && !date.isAfter(dayjs());
    },
    message
});

addMethod(date, 'lessThanTodayOrEqual', function (errorMessage: string) {
    return this.test(lessThanTodayOrEqual(errorMessage));
});
addMethod(string, 'lessThanTodayOrEqual', function (errorMessage: string) {
    return this.test(lessThanTodayOrEqual(errorMessage));
});

const isSameOrAfter = (
    fieldName: string,
    message = 'Не соответствует интервалу дат'
): TestConfig<Date | string | undefined, Maybe<AnyObject>> => ({
    name: `isSameOrAfter${fieldName}`,
    test(value: Date | string | undefined) {
        const otherFieldValue = dayjs(this.parent[fieldName]);
        return dayjs(value).isSameOrAfter(otherFieldValue);
    },
    message
});

addMethod(date, 'isSameOrAfter', function (fieldName: string, message?: string) {
    return this.test(isSameOrAfter(fieldName, message));
});
addMethod(string, 'isSameOrAfter', function (fieldName: string, message?: string) {
    return this.test(isSameOrAfter(fieldName, message));
});

addMethod(string, 'toDate', function (format: string, saveTimeZone = false) {
    return this.transform((value, originalValue) => {
        if (this.isType(value) && typeof originalValue === 'string') {
            const date = dayjs(originalValue, format);
            if (date.isValid()) {
                return date.format(saveTimeZone ? DATE_ISO_FORMAT : DATE_ISO_FORMAT_WITHOUT_TZ);
            }
        }
        return value;
    });
});

addMethod(date, 'toDate', function (format: string) {
    return this.transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
            const date = dayjs(originalValue, format);
            if (date.isValid()) {
                return date.toDate();
            }
        }
        return value;
    });
});
