import type { Directive } from 'vue';
import Inputmask from 'inputmask';
import { logger } from '@/shared/model/utils';

/**
 * Директива для привязывания маски к input-элементу
 * Маска работает на основе inputmask-core
 *
 * Важно, данные переписываются на указанный формат маски, например
 * value: '+79999999999' -> value: '+7 (999) 999-99-99'
 *
 * @example
 * Телефон
 * <InputText v-mask="'+7 (999) 999-99-99'" />
 *
 * СНИЛС
 * <InputText v-mask="'999-999-999 99'" />
 *
 * 12-ти символьный ИНН
 * <InputText v-mask="'9999-999999-99'" />
 *
 * Для постоянных значений лучше заводить модификаторы:
 *
 * Email
 * <InputText v-mask.email />
 *
 * Дата
 * <InputText v-mask.date />
 *
 * Дата и время
 * <InputText v-mask.datetime />
 *
 * Сумма
 * <InputText v-mask.currency />
 *
 * Только числовой ввод
 * <InputText v-mask.number />
 *
 * Телефон
 * <InputText v-mask.phone />
 */
function replaceChar(char: string) {
    return { d: 'д', m: 'м', y: 'г', h: 'ч' }[char.toLocaleLowerCase()] ?? '';
}
function getElement(element: HTMLElement): HTMLElement | null {
    if (element instanceof HTMLInputElement) {
        return element;
    }

    /**
     * Файл control.scss
     * .control__element - базовый класс, определяющий элемент формы
     */
    return element.querySelector('.control__element') || element.querySelector('input');
}

export default {
    created(el: HTMLElement, { value, modifiers }: { value?: unknown; modifiers: Record<string, unknown> }) {
        const [typeMask] = Object.keys(modifiers);

        const getOptions = (type = ''): Inputmask.Options =>
            ({
                phone: {
                    mask: '+7 (999) 999-99-99'
                },

                email: {
                    alias: 'email'
                },

                currency: {
                    alias: 'integer',
                    rightAlign: false,
                    groupSeparator: ' ',
                    placeholder: '',
                    allowMinus: false,
                    min: 0,
                    digits: value ? String(value) : undefined
                },

                number: {
                    alias: 'integer',
                    rightAlign: false,
                    groupSeparator: '',
                    placeholder: '',
                    allowMinus: false,
                    min: 0
                },

                /**
                 * https://github.com/RobinHerbots/Inputmask#format
                 * Маска для ввода даты работает по lowercase-нотации
                 */
                date: {
                    alias: 'datetime',
                    inputFormat: String(value)?.toLowerCase() ?? 'dd.mm.yyyy',
                    placeholder: (String()?.toLowerCase() ?? 'dd.mm.yyyy').replace(/\w/gi, replaceChar)
                },

                datetime: {
                    alias: 'datetime',
                    inputFormat: 'dd.mm.yyyy HH:MM',
                    placeholder: 'dd.mm.yyyy HH:MM'.replace(/\w/gi, replaceChar)
                },

                decimal: {
                    alias: 'decimal',
                    allowMinus: false,
                    rightAlign: false,
                    nullable: true
                },

                decimalNegative: {
                    alias: 'decimal',
                    allowMinus: true,
                    rightAlign: false,
                    nullable: true
                },

                bik: {
                    regex: '\\d{9}',
                    placeholder: ''
                },

                inn: {
                    regex: '\\d{10,12}',
                    placeholder: ''
                },

                discount: {
                    alias: 'integer',
                    rightAlign: false,
                    groupSeparator: '',
                    placeholder: '0',
                    allowMinus: false,
                    min: 0,
                    max: 100
                },

                // SEE: https://github.com/Kholenkov/js-data-validation/blob/master/data-validation.js#L70
                kpp: {
                    regex: '\\d{4}[\\dA-Z]{2}[\\d]{3}',
                    placeholder: ''
                },

                swift: {
                    regex: '[a-zA-Z\\d]{8,11}',
                    placeholder: ''
                },

                paymentAccount: {
                    regex: '\\d{20}',
                    placeholder: ''
                },

                correspondentAccount: {
                    regex: '\\d{20}',
                    placeholder: ''
                }
            })[type] || { mask: String(value) };

        const options: Inputmask.Options = {
            showMaskOnFocus: true,
            showMaskOnHover: false,
            clearMaskOnLostFocus: true,
            autoUnmask: false,
            ...getOptions(typeMask)
        };

        const element = getElement(el);
        if (!element) {
            logger.warn('Не найден элемент для монтирования inputmask', el);
            return;
        }

        Inputmask(options).mask(element);
    },
    beforeUnmount(el: HTMLElement) {
        const element = getElement(el);
        if (!element) {
            logger.warn('Не найден элемент для монтирования inputmask', el);
            return;
        }

        Inputmask.remove(element);
    }
} as Directive<HTMLElement, never>;
