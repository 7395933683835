import type { Relation, RelationRule } from '@/shared/api/types/JSON_API';

export const getRelationships = (data: Record<string, Relation[] | Relation | null>, rules: RelationRule[]) => {
    return rules.reduce<Record<string, number[] | number | null>>((acc, rule) => {
        acc[rule.rename] = [];

        if (rule.type === 'single') {
            const relation = data[rule.name] as Relation | null;
            acc[rule.rename] = relation?.id ?? null;
        } else if (Array.isArray(data[rule.name])) {
            const relations = data[rule.name] as Relation[];
            acc[rule.rename] = relations.map(item => item.id);
        } else {
            acc[rule.rename] = [];
        }

        return acc;
    }, {});
};
